import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        "&> * + *": {
            marginLeft: '10px',
        },
        alignItems: 'center',
    },
}));

const AddExpense = ({ addNewExpense }) => {
    const classes = useStyles();
    const [expense, setExpense] = useState({ name: "", value: 0});

    const handleInputChange = name => event => {
        setExpense({ ...expense, [name]: event.target.value });
    };

    return (
        <div className={classes.root}>
            <TextField
                value={expense.name}
                onChange={handleInputChange("name")}
                label="Name"
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                margin="normal"
            />
            <TextField
                value={expense.cost}
                onChange={handleInputChange("cost")}
                label="Cost"
                type="number"
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                margin="normal"
            />           
            <div>
                <Button variant="contained" color="primary" className={classes.button} onClick={() => addNewExpense(expense)}>
                    Add Expense
                </Button>
            </div>
        </div>
    );
};

export default AddExpense;