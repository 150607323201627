import React from "react";
import Header from "./Header";
import ActionButton from "../../components/buttons/ActionButton";
import HomeRouter from "./HomeRouter";
import AppMenu from "./AppMenu";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useGlobal } from "../../global";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

const actionComponents = (globalActions) => [<ActionButton text={"Logout"} onClick={globalActions.logOut} />];

const HomeLayout = () => {
  const classes = useStyles();
  const [, globalActions] = useGlobal(["auth"]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header actionComponents={actionComponents(globalActions)} />
      <AppMenu />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <HomeRouter />
      </main>
    </div>
  );
};

export default HomeLayout;
