import { useAuthCollection, buildBaseCollectionApi, getAuthCollection } from "./baseCollectionApi";
import { useCollection } from 'react-firebase-hooks/firestore';

export const ROOT_COLLECTION_NAME = "accounts";
export const COLLECTION_NAME = "expenses";

const [addObject, removeExpense] = buildBaseCollectionApi(ROOT_COLLECTION_NAME, COLLECTION_NAME);

const addExpense = (userId, expense) => {
  addObject(userId, expense);
}

export const useExpensesCollection = (userId) => {
  return useAuthCollection(ROOT_COLLECTION_NAME, COLLECTION_NAME, userId);
}

export const useMonthlyExpensesCollection = (userId, month) => {
  return useCollection(getAuthCollection(ROOT_COLLECTION_NAME, COLLECTION_NAME, userId).where("date", "==", month));
}

export { addExpense, removeExpense };