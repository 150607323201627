import { useGlobal } from "../global";
import React, { useEffect } from "react";
import { firebase } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import AppRouter from "./AppRouter";

const AppContainer = () => {
  const [user, initialising] = useAuthState(firebase.auth());
  const [globalState, globalActions] = useGlobal(["auth"]);

  useEffect(() => {
    if (user) {
      globalActions.signIn(user);
    }
  }, [user, globalActions]);

  const isLoggedIn = globalState.auth.isLoggedIn;

  return initialising || <AppRouter isLoggedIn={isLoggedIn} />;
};

export default AppContainer;
