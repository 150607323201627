export default theme => ({
    form: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    buttons: {
        display: 'flex',
        "& button": {
            flex: '1',
            "&+button": {
                marginLeft: '10px',
            }
        }
    }
});
