import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from 'react-router';
import withStyles from '@material-ui/core/es/styles/withStyles';
import styles from './AppMenu.styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const menuItems = [
  {
    name: "Dashboard",
    link: "/",
    icon: <DashboardIcon />,
  }, 
  {
    name: "Income Sources",
    link: "/incomeSources",
    icon: <AddIcon />,
  },  
  {
    name: "Expenses",
    link: "/expenses",
    icon: <RemoveIcon />,
  },  
]
class AppMenu extends Component {
  goToLink = (location) => {
    const { history } = this.props;
    history.push(location);
  }

  render() {
    const { classes } = this.props;
    return (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          {menuItems.map((menuItem, index) => (
            <ListItem button key={menuItem.name} onClick={() => this.goToLink(menuItem.link)}>
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
              <ListItemText primary={menuItem.name} />
            </ListItem>
          ))}
        </List>
        <Divider />        
      </Drawer>
    );
  }
};

export default withStyles(styles)(withRouter(AppMenu));