import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        "& + &": {
            marginTop: "5px",
        }
    },
    leftValue: {
        flex: "1",
    },
    rightValue: {
        flex: "1",
        display: 'flex',
        justifyContent: 'flex-end',
    },
    remove: {
        display: "flex",
        justifyContent: "center",
        width: "36px",
    },
    margin: {
        padding: "0",
    }
}));

const IncomeSource = ({ incomeSourceObject, removeIncomeSource }) => {
    const classes = useStyles();
    const incomeSource = incomeSourceObject.data();

    return (
        <div className={classes.root}>
            <div className={classes.leftValue}>{incomeSource.name}</div>
            <div className={classes.rightValue}>{incomeSource.value}</div>
            <div className={classes.remove}>
                <IconButton aria-label="delete" className={classes.margin} onClick={() => removeIncomeSource(incomeSourceObject.id)}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </div>
        </div>
    );
};

export default IncomeSource;