import React, { Fragment } from "react";
import LoginForm from "./login/LoginForm";
import { Route } from "react-router-dom";
import SignUpForm from "./sign-up/SignUpForm";
import withStyles from "@material-ui/core/es/styles/withStyles";



const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15%",
  },
  container: {
    minWidth: "400px",
  }
};

const AuthRouter = ({ match }) => {
  return (
    <Fragment>
      <Route
        path={`${match.url}/signUp`}
        exact
        component={SignUpForm}
      />
      <Route
        path={`${match.url}`}
        exact
        component={LoginForm}
      />
    </Fragment>
  );
};

const Auth = props => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <AuthRouter {...props} />
      </div>
    </div>
  );
};

export default withStyles(styles)(Auth);
