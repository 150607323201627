import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/es/styles/withStyles";
import styles from "./LoginForm.styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router";
import { login } from "../../api/auth";

class LoginForm extends Component {
  state = {
    username: "",
    password: "",
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  login = () => {
    const { username, password } = this.state;
    login(username, password);
  };

  goToSignUp = () => {
    const { history } = this.props;
    history.push("/login/signUp");
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper>
        <form className={classes.form}>
          <Typography variant="h4">Login</Typography>
          <TextField
            id="username"
            label="Username"
            value={this.state.username}
            onChange={this.handleChange("username")}
            margin="normal"
          />
          <TextField
            id="password"
            label="Password"
            value={this.state.password}
            onChange={this.handleChange("password")}
            margin="normal"
            type="password"
          />
          <div className={classes.buttons}>
            <Button variant="contained" color="primary" onClick={this.login}>
              Login
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.goToSignUp}
            >
              Sign Up
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

export default withStyles(styles)(withRouter(LoginForm));
