import React from "react";
import { useGlobal } from "../../global";

import { addExpenseToPlan, removeExpenseFromPlan, usePlanExpenses } from "../../api";
import Expenses from "../expenses/Expenses";


const PlanExpensesContainer = ({planId, ...props}) => {
    const [globalState,] = useGlobal(["auth"]);
    const user = globalState.auth.user;
    const [expenses] = usePlanExpenses(user.uid, planId);

    const addNewExpense = (expense) => {
        addExpenseToPlan(user.uid, planId, expense);
    }

    const removeExistingExpense = (expenseId) => {
        removeExpenseFromPlan(user.uid, planId, expenseId);
    }

    return (<Expenses {...props} user={user} expenses={expenses} addNewExpense={addNewExpense} removeExistingExpense={removeExistingExpense} />);
}

export default PlanExpensesContainer;