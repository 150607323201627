import React from "react";
import { useExpensesCollection, useMonthlyExpensesCollection, addExpense, removeExpense } from "../../api";
import { useGlobal } from "../../global";
import Expenses from "./Expenses";

const MonthyExpensesContainer = ({month, ...props}) => {
    const [globalState,] = useGlobal(["auth"]);
    const user = globalState.auth.user;
    const [expenses, loading] = useMonthlyExpensesCollection(user.uid, month);

    const addNewExpense = (expense) => {
        addExpense(user.uid, expense);
    }

    const removeExistingExpense = (expenseId) => {
        removeExpense(user.uid, expenseId);
    }

    return (<Expenses {...props} user={user} expenses={expenses} loading={loading} addNewExpense={addNewExpense} removeExistingExpense={removeExistingExpense}/>);
}

const AllExpensesContainer = (props) => {
    const [globalState,] = useGlobal(["auth"]);
    const user = globalState.auth.user;
    const [expenses, loading] = useExpensesCollection(user.uid);

    const addNewExpense = (expense) => {
        addExpense(user.uid, expense);
    }

    const removeExistingExpense = (expenseId) => {
        removeExpense(user.uid, expenseId);
    }

    return (<Expenses {...props} user={user} expenses={expenses} loading={loading} addNewExpense={addNewExpense} removeExistingExpense={removeExistingExpense}/>);
}

const ExpensesContainer = ({month, ...props}) => {
    if(month) {
        return <MonthyExpensesContainer {...props} month={month} />;
    }
    return <AllExpensesContainer {...props} />;
}

export default ExpensesContainer;
