import React from "react";
import withStyles from "@material-ui/core/es/styles/withStyles";
import styles from "./App.styles";
import AppContainer from "./AppContainer";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

function App(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AppContainer />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default withStyles(styles)(App);
