import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        "& + &": {
            marginTop: "5px",
        },
    },
    leftValue: {
        flex: "1",
    },
    rightValue: {
        flex: "1",
        display: 'flex',
        justifyContent: 'flex-end',
    },
    remove: {
        display: "flex",
        justifyContent: "center",
        width: "36px",
    },
    margin: {
        padding: "0",
    }
}));

const Expense = ({ expenseObject, removeExpense }) => {
    const classes = useStyles();
    const expense = expenseObject.data();

    return (
        <div className={classes.root}>
            <div className={classes.leftValue}>{expense.name}</div>
            <div className={classes.rightValue}>{expense.cost}</div>
            <div className={classes.remove}>
                <IconButton aria-label="delete" className={classes.margin} onClick={() => removeExpense(expenseObject.id)}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </div>
        </div>
    );
};

export default Expense;