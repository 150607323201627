import Button from "@material-ui/core/Button";
import React from "react";

const ActionButton = ({ text, onClick }) => {
  return (
    <Button color="inherit" onClick={onClick}>
      {text}
    </Button>
  );
};

export default ActionButton;
