import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IncomeSourcesList from './IncomeSourcesList';
import { addIncomeSource, useIncomeSourceCollection, removeIncomeSource } from "../../api";
import { useGlobal } from "../../global";
import AddIncomeSource from "./AddIncomeSource";

const useStyles = makeStyles(theme => ({
    root: {
    },
    right: {
        marginLeft: 'auto',
    }
}));

const IncomeSourcesContainer = () => {
    const classes = useStyles();
    const [globalState,] = useGlobal(["auth"]);
    const user = globalState.auth.user;
    const [incomeSources, loading] = useIncomeSourceCollection(user.uid);

    const addNewIncomeSource = (incomeSource) => {
        addIncomeSource(user.uid, incomeSource);
    }

    const incomeSourcesTotalValue = (incomeSources) => incomeSources.map(incomeSource => parseInt(incomeSource.data().value)).reduce((a, b) => a + b);

    return (
        <div className={classes.root}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Income Sources</Typography>
                    <Typography className={classes.right}>{loading || !incomeSources || incomeSources.docs.length === 0 ? "0" : incomeSourcesTotalValue(incomeSources.docs)}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div>
                        {!loading && incomeSources && (
                            <IncomeSourcesList incomeSources={incomeSources.docs} removeIncomeSource={(incomeSourceId) => removeIncomeSource(user.uid, incomeSourceId)}></IncomeSourcesList>
                        )}
                        <AddIncomeSource addNewIncomeSource={addNewIncomeSource}></AddIncomeSource>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};

export default IncomeSourcesContainer;