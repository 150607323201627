import * as firebase from 'firebase';
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBTfVDAaFTNK2lBGbZ-ANDA0pF9o37XokM",
    authDomain: "monethal.firebaseapp.com",
    databaseURL: "https://monethal.firebaseio.com",
    projectId: "monethal",
    storageBucket: "",
    messagingSenderId: "664403251416",
    appId: "1:664403251416:web:d4bede568924d02c"
};

const app = firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();

export default app;
export { firestore };