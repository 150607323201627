import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Expense from './Expense';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const ExpensesList = ({ expenses, removeExpense }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {expenses.map(expense => (
                <Expense expenseObject={expense} removeExpense={removeExpense}  key={expense.id}></Expense>
            ))}
        </div>
    );
};

export default ExpensesList;