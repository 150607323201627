import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ExpensesList from "./ExpensesList";
import AddExpense from "./AddExpense";

const useStyles = makeStyles(theme => ({
    root: {
    },
    right: {
        marginLeft: 'auto',
    }
}));

const Expenses = ({ user, expenses, loading, addNewExpense, removeExistingExpense }) => {
    const classes = useStyles();

   

    const expensesTotalValue = (expenses) => expenses.map(expense => parseInt(expense.data().cost)).reduce((a, b) => a + b);

    return (
        <div className={classes.root}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="expenses"
                    id="expenses"
                >
                    <Typography>Expenses</Typography>
                    <Typography className={classes.right}>{loading || !expenses || expenses.docs.length === 0 ? "0" : expensesTotalValue(expenses.docs)}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div>
                        {!loading && expenses && (
                            <ExpensesList expenses={expenses.docs} removeExpense={removeExistingExpense}></ExpensesList>
                        )}
                        <AddExpense addNewExpense={addNewExpense}></AddExpense>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};

export default Expenses;