import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IncomeSourcesContainer from '../income-sources/IncomeSourcesContainer';
import PlanExpensesContainer from './PlanExpensesContainer';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > div + div': {
            marginLeft: '10px',
        }
    },
}));

const Plan = ({plan}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <IncomeSourcesContainer />
            <PlanExpensesContainer planId={plan.id} />
        </div>
    );
};

export default Plan;