
import { firestore } from "../firebase";
import { useCollection } from 'react-firebase-hooks/firestore';

export const getAuthCollection = (rootCollection, collectionName, userId) => firestore.collection(rootCollection).doc(userId).collection(collectionName);

export const useAuthCollection = (rootCollection, collectionName, userId) => {
    return useCollection(getAuthCollection(rootCollection, collectionName, userId));
};

export const buildBaseCollectionApi = (rootCollection, collectionName) => {
    const addObject = collectionName => (userId, object) => {
        return getAuthCollection(rootCollection, collectionName, userId).add(object);
    };

    const removeObject = collectionName => (userId, objectId) => {
        return getAuthCollection(rootCollection, collectionName, userId).doc(objectId).delete();
    };

    return [addObject(collectionName), removeObject(collectionName)];
}