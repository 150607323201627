import { firestore } from "../firebase";
import { useDocument } from 'react-firebase-hooks/firestore';

export const getAuthDoc = (rootCollection, collectionName, userId, docId) => firestore.collection(rootCollection).doc(userId).collection(collectionName).doc(docId);

export const buildBaseDocumentHooks = (rootCollection, collectionName) => {
    const useAuthDocument = (userId, docId) => {
        return useDocument(getAuthDoc(rootCollection, collectionName, userId, docId));
    };

    return [useAuthDocument];
}

export const buildBaseDocumentnApi = (rootCollection, collectionName) => {
    const addDocument = (userId, docId, doc) => {
        return getAuthDoc(rootCollection, collectionName, userId, docId).set(doc, { merge: true });
    };

    const addObjectToSubcollection = subcollectionName => (userId, docId, object) => {
        return getAuthDoc(rootCollection, collectionName, userId, docId).collection(subcollectionName).add(object);
    };

    const removeObjectFromSubcollection = subcollectionName => (userId, docId, objectId) => {
        return getAuthDoc(rootCollection, collectionName, userId, docId).collection(subcollectionName).doc(objectId).delete();
    };

    return [addDocument, addObjectToSubcollection, removeObjectFromSubcollection];
}