import React, { useState } from "react";
import { useGlobal } from "../../global";
import { usePlan, addPlan } from "../../api";
import PlanSelector from "./PlanSelector";
import moment from 'moment';

const months = {
    current: moment().format("MM-YYYY"),
    last: moment().subtract(1, 'months').format("MM-YYYY")
}

const PlansContainer = () => {
    const [globalState,] = useGlobal(["auth"]);
    const user = globalState.auth.user;
    const [month, setMonth] = useState("current");
    const [plan, loading] = usePlan(user.uid, months[month]);

    const addNewPlan = () => {
        addPlan(user.uid, months[month], {});
    };

    return (<PlanSelector plan={plan} loading={loading} month={month} setMonth={setMonth} addNewPlan={addNewPlan} />);
}

export default PlansContainer;