import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IncomeSource from './IncomeSource';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const IncomeSourcesList = ({ incomeSources, removeIncomeSource }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {incomeSources.map(incomeSource => (
                <IncomeSource incomeSourceObject={incomeSource} removeIncomeSource={removeIncomeSource} key={incomeSource.id}></IncomeSource>
            ))}
        </div>
    );
};

export default IncomeSourcesList;