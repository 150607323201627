import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PlansContainer from '../plans/PlansContainer';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    item: {
        width: '100%',
    }
}));

const Dashboard = () => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Grid container justify="center" spacing={2}>
                    <Grid item className={classes.item}>
                        <PlansContainer />
                    </Grid>                   
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Dashboard;