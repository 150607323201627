import { useAuthCollection, buildBaseCollectionApi } from "./baseCollectionApi";
export const ROOT_COLLECTION_NAME = "accounts";
export const COLLECTION_NAME = "incomeSources";

const [addIncomeSource, removeIncomeSource] = buildBaseCollectionApi(ROOT_COLLECTION_NAME, COLLECTION_NAME);

export const useIncomeSourceCollection = (userId) => {
  return useAuthCollection(ROOT_COLLECTION_NAME, COLLECTION_NAME, userId);
}

export { addIncomeSource, removeIncomeSource };