import { createState } from "react-global-hook";
import { logout } from "../api/auth";

const initialState = {
  auth: {
    isLoggedIn: false,
    user: null,
  },
};

const actions = {
  signIn: (store, user) => {
    store.setState({
      auth: {
        isLoggedIn: true,
        user,
      },
    });
  },
  logOut: async (store, user) => {
    await logout();
    store.setState({
      auth: {
        isLoggedIn: false,
        user: null,
      },
    });
  }
};

const [useGlobal, getGlobal] = createState(initialState, actions);

export { useGlobal, getGlobal };
