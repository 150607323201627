import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/es/styles/withStyles';
import styles from './SignUpForm.styles';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router';
import { signUp } from "../../api/auth";

class SignUpForm extends Component {
    state = {
        username: '',
        password: '',
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    signUp = () => {
        const { username, password } = this.state;
        signUp(
            username,
            password,
        );
    };

    goToLogin = () => {
        const { history } = this.props;
        history.push("/login");
    };

    render() {
        const { classes } = this.props;
        return (
            <Paper>
                <form className={classes.form}>
                    <Typography variant="h4">
                        Sign Up
                    </Typography>
                    <TextField
                        id="username"
                        label="Username"
                        value={this.state.username}
                        onChange={this.handleChange('username')}
                        margin="normal"
                    />
                    <TextField
                        id="password"
                        label="Password"
                        value={this.state.password}
                        onChange={this.handleChange('password')}
                        margin="normal"
                        type="password"
                    />
                    <div className={classes.buttons}>
                        <Button variant="contained" color="primary" onClick={this.signUp}>
                            Sign Up
                        </Button>
                        <Button variant="contained" color="secondary" onClick={this.goToLogin}>
                            Login
                        </Button>
                    </div>
                </form>
            </Paper>
        );
    }
}
export default withStyles(styles)(withRouter(SignUpForm));
