import { firebase } from "../firebase";

export const login = (username, password) => {
  firebase.auth().signInWithEmailAndPassword(username, password);
};

export const logout = () => {
  return firebase.auth().signOut();
};

export const signUp = (username, password) => {
  firebase.auth().createUserWithEmailAndPassword(username, password);
};
