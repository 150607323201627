import { Redirect, Switch } from "react-router";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomeLayout from "../pages/home/HomeLayout";
import Auth from "../auth/Auth";
import React from "react";

const AppRouter = ({ isLoggedIn }) => {
  return (
    <Router>
      <Switch>
        <Route
          path="/login"
          render={props =>
            !isLoggedIn ? <Auth {...props} /> : <Redirect to="/" />
          }
        />
        <Route
          path="**"
          render={props =>
            isLoggedIn ? <HomeLayout {...props} /> : <Redirect to="/login" />
          }
        />
      </Switch>
    </Router>
  );
};

export default AppRouter;
