import { buildBaseDocumentnApi, buildBaseDocumentHooks, getAuthDoc } from "./baseDocumentApi";
import { useCollection } from 'react-firebase-hooks/firestore';

export const ROOT_COLLECTION_NAME = "accounts";
export const COLLECTION_NAME = "plans";

const [usePlan] = buildBaseDocumentHooks(ROOT_COLLECTION_NAME, COLLECTION_NAME);
const [addPlan, addObjectToSubcollection, removeObjectFromSubcollection] = buildBaseDocumentnApi(ROOT_COLLECTION_NAME, COLLECTION_NAME);

const addExpenseToPlan = addObjectToSubcollection('expenses');
const removeExpenseFromPlan = removeObjectFromSubcollection('expenses');
const usePlanExpenses = (userId, docId) => useCollection(getAuthDoc(ROOT_COLLECTION_NAME, COLLECTION_NAME, userId, docId).collection('expenses'));

export { usePlan, addPlan, addExpenseToPlan, removeExpenseFromPlan, usePlanExpenses }