import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Plan from './Plan';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '500px',
        padding: '10px',       
    },
    title: {
        marginBottom: '10px',
    },
    topMenu: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    monthSelect: {
        display: 'flex',
        marginBottom: '10px',

        '& > button + button': {
            marginLeft: '10px',
        }
    },
}));

const PlanSelector = ({ plan, loading, month, setMonth, addNewPlan }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <React.Fragment>
                <Typography variant="h5" component="h3" className={classes.title}>
                    Monthly plan
                </Typography>
                <div className={classes.topMenu}>
                    <div className={classes.monthSelect}>
                        <Button variant="contained" color={month === 'current' ? 'primary' : 'default'} className={classes.button} onClick={() => setMonth('current')}>
                            Current month
                        </Button>
                        <Button variant="contained" color={month === 'last' ? 'primary' : 'default'} className={classes.button} onClick={() => setMonth('last')}>
                            Last month
                        </Button>
                    </div>
                    {plan && !plan.exists && <div>
                        <Button variant="contained" color="secondary" className={classes.button} onClick={() => addNewPlan()}>
                            Add Plan
                        </Button>
                    </div>}
                </div>
                {loading || (plan && plan.exists && <Plan plan={plan} />)}
            </React.Fragment>
        </Paper >
    );
};

export default PlanSelector;