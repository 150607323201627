import { Route, Switch } from "react-router-dom";
import React from "react";
import Dashboard from "../dashboard/Dashboard";
import IncomeSourcesContainer from "../income-sources/IncomeSourcesContainer";
import ExpensesContainer from "../expenses/ExpensesContainer";

const HomeRouter = () => {
    return (
        <Switch>            
             <Route
                path="/incomeSources"
                component={IncomeSourcesContainer}
            />
            <Route
                path="/expenses"
                component={ExpensesContainer}
            />
            <Route
                path="/"
                component={Dashboard}
            />
        </Switch>
    );
};

export default HomeRouter;
